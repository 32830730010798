<template>
  <div class="smsVerification_dia">
    <van-nav-bar :title="'短信验证'"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="content">
      <van-field label="您绑定的手机"
                 v-model="iphone"
                 readonly />
      <van-field v-model="code"
                 center
                 clearable
                 type="tel"
                 label="短信验证码"
                 placeholder="请输入验证码">
        <template #button>
          <van-button size="small"
                      type="primary"
                      disabled
                      v-if="!showGetCode || countDown !== 0">{{ countDown === 0 ? "获取验证码" : `${countDown}秒重新获取` }}</van-button>
          <van-button size="small"
                      type="primary"
                      v-if="showGetCode && countDown === 0"
                      @click="getCode">获取验证码</van-button>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round
                    block
                    type="info"
                    :disabled="!canSave"
                    @click="submit">{{$t('module.confirm')}}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { checkSalaryPwd } from "@api/wx.js";
import { getSmsCode } from '@api/login.js'
import { Dialog, Toast, Notify } from "vant";
export default {
  data () {
    let firstchangepwd = this.$route.query.firstchangepwd;
    let isfirstlogin = this.$route.query.isfirstlogin;
    let nosign = this.$route.query.nosign;
    return {
      firstchangepwd,
      isfirstlogin,
      nosign,
      userInfo,
      code: '',
      showGetCode: true, // 显示获取验证码
      countDown: 0, // 验证码倒计时
      key: '', // 验证码绑定的key
      timer: null,
      leftToPath: "",
    };
  },
  beforeRouteEnter (to, from, next) {
    // console.log(from.fullPath, '1111111')
    next((vm) => {
      if (from.fullPath != "/AscLogin")      {
        vm.leftToPath = from.fullPath;
      } else      {
        location.href = localStorage.url;
      }
    });
  },
  computed: {
    iphone () {
      let tel = this.userInfo.mobilephone
      let reg = /(\d{3})\d{4}(\d{4})/
      return tel.replace(reg, "$1****$2")
    },
    canSave () {
      return this.code
    }
  },
  created () { },
  methods: {
    onClickLeft () {
      if (this.leftToPath)      {
        this.$router.push("/home");
        // this.$router.back()
      } else      {
        // 外站进入的地址
        const url = localStorage.getItem("url");
      }
    },
    onClickRight () { },
    // 发送验证码
    getCode () {
      getSmsCode({
        mobilephone: this.userInfo.mobilephone
      }).then(res => {
        if (res.iserror == 0)
        {
          Notify({ type: 'success', message: '发送验证码成功' })
          this.code = ''
          this.key = res.topvalue
          if (res.topvalue == 1234)
          {
            this.code = 1234
          }
        } else if (res.iserror == 1)
        {
          Notify({
            type: 'danger',
            message: '获取验证码失败！原因：' + res.errormsg
          })
        }
      })
      this.countDown = 59
      this.timer = setInterval(() => {
        if (this.countDown > 0)
        {
          this.countDown--
        } else
        {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    // 确定
    submit () {
      if (this.code = this.key)      {
        this.$router.push({ path: "/salarySiginList", query: { from: 'sms', firstchangepwd: this.firstchangepwd, isfirstlogin: this.isfirstlogin, nosign: this.nosign } });
      } else      {
        Notify({ type: 'danger', message: '验证码输入有误' })
      }

    },
  },
};
</script>
<style lang="less" scoped>
.smsVerification_dia {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 92px);
    overflow: auto;
    .inputPassword {
      width: 100%;
      text-align: center;
      font-size: 36px;
      margin-top: 180px;
      margin-bottom: 78px;
    }
  }
}
</style>